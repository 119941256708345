// Chakra imports
import {
  Box,
  Flex,
  Image,
  List,
  SkeletonText,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import 'react-loading-skeleton/dist/skeleton.css'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import logoImage from 'assets/img/favicon-3.png'
import { AuthContext } from 'contexts/AuthContext'
import { useContext } from 'react'
import { ConfigContext } from 'contexts/ConfigContext'
import * as marked from 'marked';

import DOMPurify from 'dompurify';
import EditorMDComponent from './EditorMDComponent'
import MDEditor from "@uiw/react-md-editor";
import { ListItem, Link } from '@chakra-ui/react';
import mermaid from "mermaid";
import { useState } from 'react'
import { useEffect } from 'react'
import { userPrivateRequest } from 'config/axios.config'
import { ImSpinner2 } from 'react-icons/im'
import { FaCheckCircle } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { CircularProgress } from '@chakra-ui/react';
import MusicCard from 'views/admin/main/others/messages/components/MusicCard'


mermaid.initialize({ startOnLoad: false });




const FileLinkItem = ({ file }) => {
  const [downloadUrl, setDownloadUrl] = useState('');

  useEffect(() => {
    fetchDownloadUrl();
  }, [file]);

  const fetchDownloadUrl = async () => {
    try {
      const response = await userPrivateRequest(`/assistant/file/getFileDownloadLink/${file.file_path.file_id}`);

      setDownloadUrl(response?.data?.data);
    } catch (error) {
      console.error('Error fetching file download URL:', error);
      // Handle error appropriately
    }
  };

  return (
    <ListItem marginBottom={'10px'}>
      <Link href={downloadUrl} isExternal color={'#0969da'} bg="#e4e8ec" p="3px 10px" display={'inline-block'} borderRadius={'5px'} fontSize={'13px'}>
        {file.text?.replace('sandbox:/mnt/data/', '')}
      </Link>
    </ListItem>
  );
};




const Code = ({ inline, children = [], className, ...props }) => {
  const [svgContent, setSvgContent] = useState("");

  const code = getCode(children);

  useEffect(() => {
    if (/^language-mermaid/.test(className?.toLocaleLowerCase() || "")) {
      try {
        mermaid.render(
          "tempId",
          code,
          (svg) => setSvgContent(svg),
          null
        );
      } catch (error) {
        setSvgContent(`Error rendering diagram: ${error.message}`);
      }
    }
  }, [code, className]);

  if (svgContent) {
    return <div dangerouslySetInnerHTML={{ __html: svgContent }} />;
  }
  return <code className={String(className)}>{children}</code>;
};

const getCode = (arr = []) =>
  arr
    .map((dt) => {
      if (typeof dt === "string") {
        return dt;
      }
      if (dt.props && dt.props.children) {
        return getCode(dt.props.children);
      }
      return false;
    })
    .filter(Boolean)
    .join("");


export default function MessageBlock(props) {
  const authData = useContext(AuthContext)
  const configData = useContext(ConfigContext)
  const config = configData.config || {}
  const {
    content,
    time,
    side,
    isLast,
    seen,
    load,
    google,
    googleHandler,
    responseType,
    copyHandler,
    criteria,
    extra,
    fullLoaded,
    files,
    selectedPrompt,
    ...rest
  } = props

  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(true);

  useEffect(() => {
    if (load) {
      setShowProgress(true);
      const interval = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress >= 100) {
            clearInterval(interval);
            setShowProgress(false);
            return 100;
          }
          return oldProgress + 10;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [load]);


  const { colorMode } = useColorMode();

  useEffect(() => {
    // Set CSS variables based on the current color mode
    document.documentElement.style.setProperty('--p-color-light', colorMode === 'light' ? '#1b254b' : 'white');
    document.documentElement.style.setProperty('--text-color-light', colorMode === 'light' ? '#1b254b' : 'white');
    document.documentElement.style.setProperty('--ol-color-light', colorMode === 'light' ? '#1b254b' : 'white');
  }, [colorMode]);

  function parseContent(content) {
    try {
      // Try to parse the content as JSON
      const parsed = JSON.parse(content);
      // console.log(parsed, 'Parsed as JSON');
    } catch (e) {
      // If parsing fails, it's likely a normal string
      // console.log(content, 'Content is a normal string');
    }
  }

  // Example usage


  useEffect(() => {
    if (parseContent(content)) {
      // console.log(JSON.parse(content), 'Assistant body')
    }

  }, [])

  const textColor = useColorModeValue("navy.700", "white");
  if (fullLoaded) {
    // console.log(JSON.stringify(content), 'openai response checking with Boss Sean')
  }

  let finalContent = content
  if (responseType == 'question') {
    if (criteria == 'blog') {
      finalContent = 'Blog Post Creation '
    } else if (criteria == 'blog-pro') {
      finalContent = 'Blog Post Creation  Pro'
    } else if (criteria == 'news') {
      finalContent = 'Video Script Creation'
    } else if (criteria == 'news-pro') {
    } else if (criteria == 'survey') {
      finalContent = 'Survey Result Summary'
    } else if (criteria == 'news-pro') {
      finalContent = 'Video Script Creation Pro'
    } else if (criteria == 'deep-studies') {
      finalContent = 'Perform Deep Studies'
    }
  }
  if (responseType === "answer" && !fullLoaded && !load)
    finalContent += ` <span className="typing-indicator">
  <span></span>
  <span></span>
  <span></span>
</span>`;
  // console.log(finalContent, 'finalContent')
  // Convert markdown to HTML using `marked`
  const rawHtml = marked.parse(finalContent);
  // console.log(rawHtml, 'finalContent rawHtml')
  // Sanitize the HTML using `DOMPurify`
  // const cleanHtml = DOMPurify.sanitize(rawHtml);
  const cleanHtml = DOMPurify.sanitize(rawHtml, {
    ADD_ATTR: ['target'],
    FORBID_TAGS: ['style'], // Example: if you want to forbid certain tags
  });
  // console.log(cleanHtml, 'finalContent cleanHtml')
  if (fullLoaded) {
    // console.log(cleanHtml, 'HTML CHECK')
  }

  const renderFiles = () => {
    return (
      <List ml="50px">
        {props?.files?.map((file, index) => (
          <FileLinkItem key={index} file={file} />
        ))}
      </List>
    );
  };







  return (
    <>
      <Box
        bg={side === "left" ? "transparent" : "transparent"}
        justifyContent="space-between"
        // alignItems="center"
        w="100%"
        {...rest}
        display="flex"
        alignItems="flex-center"
        flexWrap={"wrap"}
        className={
          responseType === "question" ? "question-type" : "question-anwser"
        }
      >
        {responseType === "question" && (
          <div style={{ marginRight: "20px" }}>
            {authData?.authState?.user?.photo && (
              <Box w="30px" h="30px" borderRadius={"4px"} color={textColor}>
                <Image
                  src={authData?.authState?.user?.photo}
                  borderRadius={"4px"}
                  height={"100%"}
                  alt="logoImage"
                  width="100%"
                />
              </Box>
            )}

            {!authData?.authState?.user?.photo && (
              <>
                <Box
                  w="30px"
                  h="30px"
                  // bg={'#121212'}
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={"4px"}
                  color={textColor}
                >
                  <FaUserCircle color="#121212" size={"30px"} />
                </Box>
              </>
            )}
          </div>
        )}

        {responseType !== "question" && (
          <Box
            w="30px"
            h="30px"
            alignItems="center"
            justifyContent="center"
            color={textColor}
            mr="20px"
          >
            <img
              src={
                config?.global?.CHAT_PAGE_CONFIG?.value?.file
                  ? config?.global?.CHAT_PAGE_CONFIG?.value?.file
                  : logoImage
              }
              alt="logoImage"
              width="30px"
            />
          </Box>
        )}

        <Box
          w="calc(100% - 64px)"
          display={"flex"}
          alignItems={"center"}
          mt="3px"
        >
          <Text
            color={textColor}
            fontSize={"14px"}
            fontFamily="'DM Sans', sans-serif"
            fontWeight="400"
            w="calc(100% - 60px)"
          >
            {responseType === "question" && (
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={cleanHtml} // Updated this line
                allowDangerousHtml // Added this line to allow the sanitized HTML to be rendered
              />
            )}

            {responseType !== "question" && (
              // <EditorMDComponent val={content} />

              <>
                {criteria === "tts" && content    ? (
                  <Box display="flex" justifyContent="center">
                    <MusicCard url={content} />
                  </Box>
                ) : (
                  <MDEditor
                    // onChange={(newValue = "") => setValue(newValue)}
                    textareaProps={{
                      placeholder: "Please enter Markdown text",
                    }}
                    height={500}
                    value={content}
                    data-color-mode="light"
                    className="response-markdown"
                    previewOptions={{
                      components: {
                        code: Code,
                      },
                    }}
                    color={textColor}
                  />
                )}
              </>
            )}
            {extra && (
              <Text
                fontFamily="'Dm Sans', sans-serif"
                fontWeight="400"
                color={textColor}
                fontSize={"12px"}
              >
                {extra}
              </Text>
            )}

            {load && (
              <div className="loading-area update-area">
                {selectedPrompt?.assistant?.assistantId && (
                  <Box
                    paddingTop="5px"
                    d="flex"
                    gap="10px"
                    alignItems={"center"}
                  >
                    <>
                      {showProgress && props.load && (
                        <>
                          <CircularProgress
                            value={progress}
                            color="#834af4"
                            size={4}
                          />
                          Thinking
                        </>
                      )}
                      {/* <ImSpinner2 className="spinner" />   */}
                    </>
                  </Box>
                )}
                {!selectedPrompt?.assistant?.assistantId && (
                  <Box paddingTop="20px">
                    <SkeletonText
                      mt="0"
                      noOfLines={1}
                      spacing="5"
                      skeletonHeight="10"
                    />
                    <SkeletonText
                      mt="4"
                      noOfLines={5}
                      spacing="5"
                      skeletonHeight="3"
                    />
                  </Box>
                )}
              </div>
            )}
          </Text>
        </Box>
        {/* ...existing JSX */}
      </Box>
      {fullLoaded && renderFiles()}
    </>
  );
}

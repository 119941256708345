import ChatPromtPagination from "components/ChatPromtPagination";
import useMediaQuery from "components/useMediaQuery";
import { userPublicRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import useCustomHistory from "langHoc/useCustomHistory";
import { Fade, Grid, Slug } from "mauerwerk";
import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BsBorderWidth, BsPin } from "react-icons/bs";
import NativeMeClone from "./NativeMeClone";
import { FiX } from "react-icons/fi";
import {
  Badge,
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import PromptModal from "layouts/HomeNew/Search/PromptModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ModalContext } from "contexts/ModalContext";
import { SessionListContext } from "contexts/SessionListContext";
import urlParser from "url";
import Card from "components/card/Card";
import { hasModuleScopeEligibility } from "utils/utils";

function OverflowText({ data }) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, "text/html");
  Array.from(doc.getElementsByTagName("img")).forEach((img) => {
    img.parentNode.removeChild(img);
  });

  const truncateToThreeSentences = (text) => {
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
    return sentences.slice(0, 3).join(" ");
  };
  const textContent = doc.body.textContent || "";
  const truncatedContent = truncateToThreeSentences(textContent);

  return (
    <div
      style={{
        maxHeight: "4.5em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "secondaryGray.600",
        fontSize: "md",
        fontWeight: "400",
        lineHeight: "1.5em",
        marginBottom: "1em",
      }}
    >
      {truncatedContent}
    </div>
  );
}

const Item = ({
  data,
  promptGeneration,
  selectedPrompt,
  isKor,
  primaryColor,
  bgColor,
}) => {
  const textColor = useColorModeValue("navy.700", "white");
  return (
    <Flex
      direction={{ base: "column" }}
      justify="center"
      key={data}
      onClick={() => {
        promptGeneration(data);
      }}
      h="300px"
    >
      <Box position="relative" w="100%" h="150px">
        <Image
          src={data?.image ?? ""}
          alt=""
          borderRadius="20px"
          width="100%"
          height="100%"
          objectFit="contain"
        />
        {data?.sourceBadge && (
          <Badge
            fontSize="12px"
            colorScheme="green"
            position={"absolute"}
            right={"0px"}
            top={"0px"}
            color={"#fff"}
            borderRadius={"20px"}
            paddingY={"8px"}
            paddingLeft={"15px"}
            paddingRight={"15px"}
            backgroundColor={primaryColor}
            textTransform={"capitalize"}
          >
            {data?.sourceBadge}
          </Badge>
        )}
      </Box>

      <Flex flexDirection="column" justify="space-between" h="100%" mt="10px">
        <div>
          <Text
            color={textColor}
            fontSize={"18px"}
            mb="5px"
            fontWeight="bold"
            me="14px"
          >
            {isKor ? data?.titleKr : data?.title}
          </Text>
          <OverflowText
            data={
              isKor ? (data?.messageKr ? data?.messageKr : "") : data?.message
            }
          />
        </div>
      </Flex>
    </Flex>
  );
};

const ChatPagePrompt2 = ({
  promptGeneration,
  selectedPrompt,
  setMicModal,
  setSelectedPrompt,
  homepageModalClose,
  setVoiceModule,
  selectedFilter,
  onOpen,
  selectedVoice,
  setSelectedVoice,
}) => {
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const [loading, setLoading] = useState(true);
  const [totalRow, setTotalRow] = useState(0);
  const history = useCustomHistory();
  const authData = useContext(AuthContext);
  const { setDepositModal } = authData;
  const membership = authData?.authState?.membership;
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const [page, SetPage] = useState(1);
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState("");
  const [sort, setSort] = useState("");
  const [query, setQuery] = useState("");
  const { i18n } = useTranslation();
  const dvc_768 = useMediaQuery("(max-width: 768px)");
  const [modal, setModal] = useState("");
  const [modalData, setModalData] = useState({});
  const sessionListProvider = useContext(SessionListContext);
  const { setPagi } = sessionListProvider;

  const modalContextData = useContext(ModalContext);

  const colors = config?.global?.COLORS?.value;
  const primaryColor = useColorModeValue(
    colors?.primaryColor ? colors?.primaryColor : "navy.700",
    colors?.primaryDarkColor ? colors?.primaryDarkColor : "white"
  );

  const bgColor = useColorModeValue(
    colors?.bgColor ? colors?.bgColor : "navy.700",
    colors?.bgDarkColor ? colors?.bgDarkColor : "white"
  );

  const location = useLocation();

  useEffect(() => {
    // Reset selectedPrompt when there's a navigation change
    setSelectedPrompt({});
  }, [location.key]);

  let queryData =
    urlParser.parse(window.location.href, { parseQueryString: true }).query ||
    {};
  let queryvalue = queryData.mode || "";

  const columns =
    queryvalue === "voice"
      ? config?.global?.TILES_CONFIG?.value?.["chatpageVoice"]?.columns
      : config?.global?.TILES_CONFIG?.value?.["chatpage"]?.columns;
  const spacing =
    queryvalue === "voice"
      ? Number(config?.global?.TILES_CONFIG?.value?.["chatpageVoice"]?.spacing)
      : Number(config?.global?.TILES_CONFIG?.value?.["chatpage"]?.spacing);

  const [items, setItems] = useState({
    data: [],
    columns: columns ? columns : 2,
    margin: spacing ? spacing : 10,
    filter: "",
    height: false,
  });

  const [offSet, setOffset] = useState(
    queryvalue === "voice"
      ? Number(
          config?.global?.TILES_CONFIG?.value?.["chatpageVoice"]?.itemPerPage
        )
      : Number(config?.global?.TILES_CONFIG?.value?.["chatpage"]?.itemPerPage)
  );

  const fetchData = async () => {
    try {
      setLoading(true);
      let queryData =
        urlParser.parse(window.location.href, { parseQueryString: true })
          .query || {};
      let queryvalue = queryData.mode || "";

      let queryString = "";

      // if (membership === "free" && i18n?.language === "en") {
      //   queryString = "&toPro=true";
      // }

      // if (membership === "free" && i18n?.language === "ko") {
      //   queryString = "&toProKo=true";
      // }
      if (!authData?.authState?.isAuthenticated && membership === "guest") {
        queryString = "&userType=unAuthenticatedUser";
      } else if (authData?.authState?.isAuthenticated) {
        queryString = "&userType=authenticatedUser";
      }
      // if (
      //   !authData?.authState?.isAuthenticated &&
      //   membership === "guest" &&
      //   i18n?.language === "ko"
      // ) {
      //   queryString = "&toGuestKo=true";
      // }

      // if (membership === "pro" && i18n?.language === "en") {
      //   queryString = "&toPro=true";
      // }
      // if (membership === "pro" && i18n?.language === "ko") {
      //   queryString = "&toProKo=true";
      // }
      if (category) {
        queryString += `&category=${category?.value}`;
      }
      if (selectedFilter) {
        queryString += `&category=${selectedFilter}`;
      }
      if (sort) {
        queryString += `&sortBy=createdAt&sortOrder=${sort?.value}`;
      }
      if (query) {
        queryString += `&query=${query}`;
      }

      if (queryvalue === "voice" && i18n?.language === "en") {
        queryString = `&toVoiceMode=true`;
      }

      if (queryvalue === "voice" && i18n?.language === "ko") {
        queryString = `&toVoiceModeKo=true`;
      }

      const results = await userPublicRequest.get(
        `/get-prompts?page=${page}&limit=${offSet}&lang=${
          i18n?.language
        }&source=admin${queryString}&promptType=${
          queryvalue === "voice" ? "" : ""
        }`
      );
      // /assistant

      const pagi = results?.data?.data?.docs?.find((rs) => {
        if (rs.promptType === "apps" && !rs.redirectionUrl) {
          return rs;
        }
      });

      setPagi(pagi);
      let docs = [...results?.data?.data?.docs];

      setItems({ ...items, data: docs });
      setLoading(false);
      setTotalRow(results?.data?.data?.totalDocs);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authData?.isFetched) {
      fetchData(page);
    }
  }, [
    page,
    category,
    sort,
    selectedFilter,
    authData?.isFetched,
    authData?.authState?.isAuthenticated,
  ]);

  const fetchCategories = async () => {
    try {
      const results = await userPublicRequest.get(`/prompt-categories`);
      setCategories(results?.data?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  let columnsData = [];

  columnsData = useMemo(
    () =>
      categories?.map((cat) => {
        return {
          id: cat?._id,
          value: cat?._id,
          label: cat?.title,
          labelKr: cat?.titleKr,
        };
      }),
    [categories]
  );

  const isPagiEnabled = (data) => {
    // console.log("I am okay");
    // return;
    if (data?.slides && data?.slides?.length > 0) {
      setModal(true);
      setModalData(data);
      return;
    }
    if (data?.promptType === "tts") {
      modalContextData.setCurrentModalName("ttsModal");
      modalContextData.setModalData({
        data,
        onClose: () => {
          modalContextData.setCurrentModalName(null);
          modalContextData.setModalData({});
        },
      });
      return;
    } else if (data?.redirectionUrl) {
      if (data?.redirectionUrl.includes("." + window.location?.hostname)) {
        // Subdomain apps
        window.open(`${data?.redirectionUrl}/${i18n.language}`);
      } else if (!data?.redirectionUrl.includes(window.location?.hostname)) {
        // Third party redirection
        window.open(`${data?.redirectionUrl}`);
      } else {
        // In app redirection
        window.location.href = data?.redirectionUrl;
      }
    } else {
      setMicModal();
    }
  };

  const modalCloseHandler = (data) => {
    if (data.redirectionUrl) {
      window.open(`${data?.redirectionUrl}/${i18n.language}`);
    } else {
      setMicModal();
    }
    setModal(false);
    setModalData({});
  };

  const Cell = ({
    toggle,
    data,
    height,
    description,
    css,
    maximized,
    setSelectedPrompt,
    homepageModalClose,
    onOpen,
  }) => {
    const messageRef = useRef(null);
    const textColor = useColorModeValue("navy.700", "white");

    const { colorMode } = useColorMode();
    const isDark = colorMode === "dark";

    useEffect(() => {
      if (messageRef.current && isDark) {
        Array.from(messageRef.current.querySelectorAll("*")).forEach(
          (element) => {
            element.style.color = "#fff"; // Set text color to white for dark mode
            // Apply other style changes as needed
          }
        );
      }
      // Make sure to include dependencies that trigger this effect
    }, [isDark, messageRef.current]);

    // const shouldShow = () => {
    //   let modalDisabledDate = localStorage.getItem("modalDisabledDate");
    //   if (!modalDisabledDate) {
    //     return true;
    //   }
    //   return dayjs(modalDisabledDate) < dayjs();
    // };

    useEffect(() => {
      let queryData =
        urlParser.parse(window.location.href, { parseQueryString: true })
          .query || {};
      let queryvalue = queryData.selectedShortcutPrompt || "";

      if (homepageModalClose && data?._id === queryvalue) {
        if (data.promptType === "apps") {
          isPagiEnabled(data);
        } else {
          toggleHandler();
          setSelectedPrompt(data);
        }
        history.replace("/chat");
      }
    }, [homepageModalClose]);

    useEffect(() => {
      const modifyAnchors = () => {
        const div = messageRef.current;

        if (div) {
          const anchors = div.getElementsByTagName("a");
          Array.from(anchors).forEach((a) => {
            let href = a.getAttribute("href");
            if (href) {
              if (!href.startsWith("http://") && !href.startsWith("https://")) {
                href = `http://${href}`;
              }
              a.setAttribute("href", href);
              a.style.color = "#0000EE";
              a.target = "_blank";
            }
          });
        }
      };

      modifyAnchors();
    }, []);

    const toggleHandler = () => {
      if (queryvalue === "voice") {
        // Request audio permission from the user
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            console.log("Audio permission granted");

            // If permission is granted, set up the voice module
            setVoiceModule(data);
            // setModalData(data);
            console.log(401, "selectedPrompt");
            setSelectedPrompt(data);
          })
          .catch((error) => {
            console.error("Audio permission denied:", error);
            // Handle the case where the user denies permission
            return; // Return from the function
          });
      } else if (data?.slides && data?.slides?.length > 0) {
        setModal(true);
        setSelectedPrompt(data);
        setModalData(data);
        return;
      } else {
        setSelectedPrompt(data);
        toggle();
      }
    };

    const modalRegularCloseHandler = () => {
      toggle();
      setModal(false);
      setModalData({});
    };

    return (
      <Box>
        {modal && data._id === modalData?._id && (
          <PromptModal
            modalData={modalData}
            modalCloseHandler={
              data.promptType === "apps"
                ? modalCloseHandler
                : modalRegularCloseHandler
            }
            closeModal={() => setModal(false)}
          />
        )}

        {data.promptType === "apps" || data.promptType === "tts" ? (
          <Card
            p="20px"
            className={` p-${data?._id} ${
              selectedPrompt._id === data._id ? "active" : ""
            } ${data?.isPinned === "yes" ? "pinned" : ""}   ${
              !data?.isPagi === "yes" ? "pagi-active" : ""
            } joyrideapps`}
            key={data?._id}
            onClick={() => {
              let balance = authData?.authState?.user?.creditBalance ?? 0;
              let freeStatus = isKor ? data?.toFreeKo : data?.toFree;

              let checkScope = hasModuleScopeEligibility(
                data,
                authData?.authState?.user?.scopes ?? {},
                authData?.authState?.user
              );
              let web3Auth =
                configData?.config?.global?.WEB3_AUTHENTICATION?.value === "YES"
                  ? true
                  : false;

              if (!authData?.authState?.isAuthenticated) {
                onOpen();
                return true;
                // } else if (web3Auth && membership === "free" && !freeStatus) {
              } else if (web3Auth && balance <= 0) {
                setDepositModal(true);
                return;
              } else if (!web3Auth && !checkScope) {
                modalContextData.setCurrentModalName(
                  "moduleEligibilityException"
                );
              } else {
                isPagiEnabled(data);
              }
            }}
            style={{
              cursor: !maximized ? "pointer" : "auto",
              // marginLeft: maximized ? "10px" : "0px",
              marginTop: maximized ? 0 : -Number(items?.margin),
              marginBottom: maximized ? 0 : -Number(items?.margin) + "50px",
              // boxShadow: "0px 1px 3px 0px #ddd",
            }}
          >
            <Flex
              direction={{ base: "column" }}
              justify="center"
              key={data}
              h="300px"
            >
              <Box position="relative" w="100%" h="150px">
                <Image
                  src={data?.image ?? ""}
                  alt=""
                  borderRadius="20px"
                  width="100%"
                  height="100%"
                  objectFit="contain"
                />
                {data?.sourceBadge && (
                  <Badge
                    fontSize="14px"
                    colorScheme="green"
                    position={"absolute"}
                    right={"0px"}
                    top={"0px"}
                    color={"#fff"}
                    borderRadius={"20px"}
                    paddingY={"8px"}
                    paddingLeft={"15px"}
                    paddingRight={"15px"}
                    backgroundColor={primaryColor}
                    textTransform={"capitalize"}
                  >
                    {data?.sourceBadge}
                  </Badge>
                )}
              </Box>

              <Flex
                flexDirection="column"
                justify="space-between"
                h="100%"
                mt="10px"
              >
                <div>
                  <Text
                    color={textColor}
                    // fontSize={{
                    //   base: "xl",
                    //   md: "lg",
                    //   lg: "lg",
                    //   xl: "lg",
                    //   "2xl": "md",
                    //   "3xl": "lg",
                    // }}
                    fontSize={"18px"}
                    mb="5px"
                    fontWeight="bold"
                    me="14px"
                  >
                    {isKor ? data?.titleKr : data?.title}
                  </Text>
                  <OverflowText
                    data={
                      isKor
                        ? data?.messageKr
                          ? data?.messageKr
                          : ""
                        : data?.message
                    }
                  />
                </div>
              </Flex>
            </Flex>
          </Card>
        ) : (
          <Card
            p="20px"
            style={{
              cursor: !maximized ? "pointer" : "auto",
              // marginLeft: maximized ? "10px" : "0px",
              marginTop: maximized ? 0 : -Number(items?.margin),
              marginBottom: maximized ? 0 : -Number(items?.margin) + "50px",
              boxShadow: "0px 0px 0px 0px #ddd",
            }}
            className="joyrideapps"
          >
            <Box onClick={!maximized ? toggleHandler : undefined}>
              <Fade show={maximized} delay={maximized ? 500 : 0}>
                <Flex direction={{ base: "column" }}>
                  <Slug delay={600}>
                    <Box
                      mb={{ base: "20px", "2xl": "20px" }}
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      w="100%"
                      h="150px"
                    >
                      {" "}
                      <Image
                        src={data?.image ?? ""}
                        alt=""
                        borderRadius="20px"
                        width="100%"
                        height="100%"
                        objectFit="contain"
                      />
                      <Box
                        onClick={() => {
                          promptGeneration(data);
                          toggle();
                        }}
                      >
                        <FiX color="#777777" size={22} />
                      </Box>
                    </Box>

                    <Text
                      color={textColor}
                      fontSize={"18px"}
                      mb="5px"
                      fontWeight="bold"
                      me="14px"
                    >
                      {isKor ? data?.titleKr : data?.title}
                    </Text>
                    <Text color={textColor}>
                      <div
                        ref={messageRef}
                        dangerouslySetInnerHTML={{
                          __html: isKor
                            ? data?.messageKr
                              ? data?.messageKr
                              : ""
                            : data?.message,
                        }}
                      />
                    </Text>
                  </Slug>
                </Flex>
              </Fade>

              <Fade
                show={!maximized}
                from={{ opacity: 0, transform: "translate3d(0,140px,0)" }}
                enter={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
                leave={{ opacity: 0, transform: "translate3d(0,-50px,0)" }}
                delay={maximized ? 0 : 400}
              >
                <div>
                  <Item
                    data={data}
                    promptGeneration={promptGeneration}
                    selectedPrompt={selectedPrompt}
                    isKor={isKor}
                    primaryColor={primaryColor}
                    bgColor={bgColor}
                  />
                </div>
              </Fade>
            </Box>
          </Card>
        )}
      </Box>
    );
  };

  const [maximizedCell, setMaximizedCell] = useState(null);
  return (
    <Box>
      {/* <NativeMeClone config={config} /> */}

      {/* //GRID ACT AS SIMPLE GRID */}
      {!loading && (
        <Grid
          data={items?.data}
          keys={(d) => d._id}
          heights={(d) => {
            return maximizedCell === d._id
              ? 100
              : items.height
              ? d.height
              : 330;
          }}
          // heights={400}
          columns={dvc_768 ? 1 : Number(items?.columns)}
          margin={20}
          lockScroll={true}
        >
          {(data, maximized, toggle) => {
            setMaximizedCell(maximized);
            return (
              <Cell
                data={data}
                setSelectedPrompt={setSelectedPrompt}
                maximized={maximized}
                onOpen={onOpen}
                toggle={() => {
                  // toggle();
                  let balance = authData?.authState?.user?.creditBalance ?? 0;
                  let freeStatus = isKor ? data?.toFreeKo : data?.toFree;

                  let checkScope = hasModuleScopeEligibility(
                    data,
                    authData?.authState?.user?.scopes ?? {},
                    authData?.authState?.user
                  );
                  let web3Auth =
                    configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
                    "YES";
                  if (!authData?.authState?.isAuthenticated) {
                    onOpen();
                    setSelectedPrompt({});
                    return true;
                    // } else if (web3Auth && membership === "free" && !freeStatus) {
                  } else if (web3Auth && balance <= 0) {
                    setSelectedPrompt({});
                    // modalContextData.setCurrentModalName(
                    //   "moduleEligibilityException"
                    // );

                    setDepositModal(true);
                    return;
                  } else if (!web3Auth && !checkScope) {
                    setSelectedPrompt({});
                    modalContextData.setCurrentModalName(
                      "moduleEligibilityException"
                    );
                  } else {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    toggle(() => {
                      const isCurrentlyMaximized = maximizedCell === data._id;
                      setMaximizedCell(isCurrentlyMaximized ? null : data._id);
                    });
                  }
                }}
                homepageModalClose={homepageModalClose}
              />
            );
          }}
        </Grid>
      )}
      {!selectedPrompt?._id && (
        <ChatPromtPagination
          limit={offSet}
          page={page}
          totalRow={totalRow}
          handlePageChange={(limit, newOffset, page) => {
            SetPage(page);
          }}
        />
      )}
    </Box>
  );
};

export default React.memo(ChatPagePrompt2);

import { userPrivateRequest } from "config/axios.config";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { indexBy } from "underscore";
import { AuthContext } from "./AuthContext";
import SessionListReducer from "./reducers/SessionListReducer";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ConfigContext } from "./ConfigContext";

export const SessionListContext = createContext();

const initialState = {};

const SessionListProvider = ({ children }) => {
  const history = useHistory();
  const [records, setRecords] = useState({});
  const [pagi, setPagi] = useState(null);
  const [isFetched, setIsFetched] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState({});
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [currentSessionInfo, setCurrentSessionInfo] = useState({});
  const [hasMoreData, setHasMoreData] = useState(true);
  const [totalRow, setTotalRow] = useState(0);
  const [state, dispatch] = useReducer(SessionListReducer, initialState);
  let memorySource = "local";
  const configData = useContext(ConfigContext);
  const authData = useContext(AuthContext);

  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  let isAuthenticated = authData.authState.isAuthenticated || false;
  const fetchSession = async (page = 1, limit = 20) => {
    // if (!isAuthenticated) {
    //   let memoryData = localStorage.getItem("memoryHistory");
    //   let memoryHistory = JSON.parse(memoryData ?? "{}");
    //   let finalHistory = {};
    //   Object.keys(memoryHistory).map((key, index) => {
    //     finalHistory[key] = memoryHistory[key];
    //   });
    //   setRecords(finalHistory);
    //   return;
    // }
    try {
      let fetchedRecords = await userPrivateRequest
        .get(`/chat-session/paginatedList?page=${page}&limit=${limit}`)
        .then((response) => {
          setHasMoreData(response.data?.hasMore ?? false);
          setTotalRow(response.data?.totalData ?? 0);
          return response.data.records || [];
        });
      fetchedRecords = fetchedRecords.map((record) => {
        return {
          id: record._id,
          title: record.title,
          edit: false,
          delete: false,
          source: record?.source ?? "chat",
          agiId: record?.agiId ?? "",
          isDiscovered: record?.isDiscovered ?? "yes",
        };
      });
      memorySource = "db";
      setRecords({ ...records, ...indexBy(fetchedRecords, "id") });
      setIsFetched(true);
    } catch (err) {
      setRecords({});
      setIsFetched(true);
    }
  };

  const addNewSession = (id, title, isDiscovered = "yes") => {
    let isAuthenticated = authData.authState.isAuthenticated || false;
    if (Object.keys(records)?.indexOf(id) === -1) {
      let newData = {
        [id]: { title: title, isDiscovered },
        ...records,
      };

      setRecords(newData);

      // if (isAuthenticated) {
      // } else {
      //   localStorage.setItem("memoryHistory", JSON.stringify(newData));
      // }
    }
  };

  const editOnChange = (id, record) => {
    setRecords({
      ...records,
      [id]: record,
    });
  };
  const editSessionTitleSubmit = (id) => {
    let record = records[id];
    let newRecords = {
      ...records,
      [id]: { ...record, edit: false },
    };
    setRecords(newRecords);
    if (isAuthenticated) {
      userPrivateRequest
        .patch("/chat-session/update", {
          id,
          title: record.title,
        })
        .then((response) => {
          return response.data.records || [];
        });
    }
    // else {
    //   localStorage.setItem("memoryHistory", JSON.stringify(newRecords));
    // }
  };
  const deleteSession = async (id) => {
    let newData = records;
    delete newData[id];
    // localStorage.setItem("memoryHistory", JSON.stringify(newData));
    setRecords(newData);
    userPrivateRequest.post("/chat-session/delete/" + id).then((response) => {
      return response.data.records || [];
    });
  };

  useEffect(() => {
    fetchSession();
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(records);
  }, [records]);

  useEffect(() => {
    let defaultPage =
      configData?.config?.global?.DEFAULT_PAGE_SETTING?.value?.page ?? "search";
    let prefix = ["/", "/en", "/ko", "/en/", "/ko/"];

    if (defaultPage === "chat" && prefix.indexOf(lang?.pathname) !== -1) {
      isKor ? history.push("/ko/chat") : history.push("/en/chat");
    } else if (
      defaultPage === "search" &&
      prefix.indexOf(lang?.pathname) !== -1
    ) {
      isKor ? history.push("/ko") : history.push("/en");
    } else if (
      defaultPage === "landing" &&
      lang?.pathname === "/" &&
      !isAuthenticated
    ) {
      // isKor ? history.push("/ko/landing") : history.push("/en/landing");
    }
  }, []);
  return (
    <SessionListContext.Provider
      value={{
        fetchSession,
        records: state,
        memorySource: memorySource,
        addNewSession,
        editSessionTitleSubmit,
        deleteSession,
        editOnChange,
        dispatch,
        isFetched,
        selectedPrompt,
        setSelectedPrompt,
        currentSessionInfo,
        setCurrentSessionInfo,
        pagi,
        setPagi,
        setCurrentSessionId,
        currentSessionId,
        hasMoreData,
        totalRow,
        selectedVoice,
        setSelectedVoice,
      }}
    >
      {children}
    </SessionListContext.Provider>
  );
};

export default SessionListProvider;

import {
  Box,
  Button,
  Icon,
  Text,
  useDisclosure,
  Tooltip,
  Flex,
  useColorModeValue,
  useColorMode,
  Portal,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useContext, useState, useEffect, useRef } from "react";
import {
  FiPhone,
  FiRefreshCw,
  FiSend,
  FiShare2,
  FiSquare,
  FiUpload,
} from "react-icons/fi";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";
import { CiFileOn } from "react-icons/ci";
import { IoCloseCircleOutline } from "react-icons/io5";
import HoverIconButton from "components/icons/HoverIconButton";
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import MicV2 from "components/audio/MicV2";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import { userPrivateRequest } from "config/axios.config";
import { useTranslation } from "react-i18next";
import { ModalContext } from "contexts/ModalContext";
import useCustomHistory from "langHoc/useCustomHistory";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { BsInfinity } from "react-icons/bs";
import MetamaskLoginButton from "views/auth/signIn/MetamaskLoginButton";
import InitialModal from "../../NewMessage/components/InitialModal";
import { FaUserCircle } from "react-icons/fa";

function FileUploadButton({ onFilesSelected }) {
  // Ref for the file input
  const fileInputRef = useRef(null);

  // Function to handle file input click
  const handleIconClick = (e) => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length) {
      onFilesSelected(files);
      // Reset the file input
      e.target.value = "";
    }
  };

  return (
    <>
      <Tooltip label="Upload" fontSize="md" borderRadius="8px">
        <Button
          bg={"transparent"}
          _hover={{
            bg: "transparent",
            backgroundColor: "rgb(230, 230, 230)",
            borderRadius: "5px",
            height: "40px",
          }}
          id="_ee"
          px="0px"
          ml="10px"
          mt="-1px"
          width="40px"
          height="40px"
          onClick={handleIconClick}
          type="button"
          cursor={"pointer"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FiUpload size={"20px"} color="red" />
        </Button>
      </Tooltip>

      {/* Invisible file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        multiple
      />
    </>
  );
}

const FileUploadItem = ({ file, onRemove }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      key={file.name}
      d="flex"
      gap="10px"
      p="5px 10px"
      alignItems="center"
      bg="#e4e8ec"
      w="auto"
      fontSize={"13px"}
      borderRadius="5px"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <IoCloseCircleOutline onClick={() => onRemove(file.id)} />
      ) : file.status === "loading" ? (
        <ImSpinner2 className="spinner" />
      ) : (
        <CiFileOn />
      )}
      - {file.name}
      {file.status === "error" && <span> - {file.message}</span>}
    </Box>
  );
};

const FloatingMessageBar = ({
  question,
  setQuestion,
  responseProgressRef,
  partiallyStoppedRef,
  selectedPrompt,
  submitHandler,
  sessionIdRef,
  urlParser,
  isTutorial,
  isPagiCall,
  setShowModal,
  handleStopResponse,
  handleRegenerate,
  assistModal,
  inputPlaceholder,
  setAssistModal,
  currentSessionInfo,
  micModal,
  setMicModal,
  recording,
  setRecording,
  auto,
  setAuto,
  fileUploads,
  setFileUploads,
}) => {
  const [isMultilined, setIsMultilined] = useState(false);
  const [textareaHeight, setTextAreaHeight] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disableIcon, setDisableIcon] = useState(false);
  // const [fileUploads, setFileUploads] = useState([]);
  const [messages, setMessages] = useState([]);
  const { authState, dispatch, isFetched, depositModal, setDepositModal } =
    useContext(AuthContext);

  const configData = useContext(ConfigContext);
  const modalContextData = useContext(ModalContext);

  const config = configData.config || {};
  const COLORS = configData?.config?.global?.COLORS?.value;

  const borderColor = useColorModeValue(
    COLORS.borderColor,
    COLORS.borderDarkColor
  );
  const hoverColor = useColorModeValue(
    COLORS.hoverColor,
    COLORS.hoverDarkColor
  );
  const textColor = useColorModeValue(COLORS.textColor, COLORS.textDarkColor);
  const placeholderColor = useColorModeValue(
    COLORS.placeholderColor,
    COLORS.placeholderDarkColor
  );
  const iconColor = useColorModeValue(COLORS.iconColor, COLORS.iconDarkColor);

  const textareaRef = useRef(null);

  const { t } = useTranslation();
  const history = useCustomHistory();
  const membership = authState?.membership;

  const secondary = true;
  // From component NavbarLinksAdmin
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  let menuBg = useColorModeValue("white", "navy.800");
  const ethColor = useColorModeValue("gray.700", "white");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isShareModal,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure();
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await userPrivateRequest.post(
        "/assistant/file/upload",
        formData
      );
      return {
        success: true,
        message: "Upload successful",
        file,
        response: response?.data?.response, // Return the response data
      };
    } catch (error) {
      return {
        success: false,
        message: error.message || "Upload failed",
        file,
        response: null, // Return null in case of error
      };
    }
  };
  const handleRemoveFile = (fileId) => {
    setFileUploads((prevUploads) =>
      prevUploads.filter((file) => file.id !== fileId)
    );
  };
  const handleShare = () => {
    if (!authState?.isAuthenticated) {
      onOpen();
      return;
    }
    if (messages.length <= 0) {
      toast("No message found.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    onShareModalOpen();
  };
  const handleSend = (e) => {
    e.preventDefault();
    if (!authState?.isAuthenticated) {
      onOpen();
      return;
    }
    if (question.length <= 0 || !question) {
      toast("No message found.", {
        // position: toast.POSITION.BOTTOM_RIGHT,
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    checkIfPagi(e);
  };
  const textareaEnterHandler = (e) => {
    if (!authState?.isAuthenticated) {
      onOpen();
      return;
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      checkIfPagi(e);
    }
  };
  const audioTextBack = (text) => {
    submitHandler(null, text);
  };
  //* Find status of fullLoaded of Last answer of a Conversation
  const checkIsLast = () => {
    if (messages?.length) {
      return messages[messages?.length - 1]?.ai?.fullLoaded;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "46px";
      let scrollHeight = textareaRef.current.scrollHeight;
      if (scrollHeight === 0) {
        scrollHeight = 46;
      }
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [question, messages]);
  const checkIfPagi = (e) => {
    if (Object.keys(selectedPrompt).length > 0 || sessionIdRef.current) {
      submitHandler(e);
    } else {
      let balance = authState?.user?.creditBalance ?? 0;
      let web3Auth =
        configData?.config?.global?.WEB3_AUTHENTICATION?.value === "YES"
          ? true
          : false;
      // if (!web3Auth && membership === "free") {
      if (!web3Auth && balance <= 0) {
        modalContextData.setCurrentModalName("moduleEligibilityException");
        return;
      } else if (web3Auth && balance <= 0) {
        setDepositModal(true);
        return;
      }
      setMicModal(true);
      setRecording(false);
    }
  };
  const isObjectEmpty = (obj) => obj && Object.keys(obj).length === 0;
  useEffect(() => {
    let queryData =
      urlParser.parse(window.location.href, { parseQueryString: true }).query ||
      {};
    if (messages.length > 0 || isTutorial) {
      setShowModal(false);
    }
    if (messages.length < 1 && !isTutorial) {
      console.log(!isPagiCall && !queryData.sharedSession, "SHOWMODAL");
      if (!isPagiCall && !queryData.sharedSession && !sessionIdRef.current) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    }
  }, [messages, isTutorial, isPagiCall]);
  const handleFilesSelected = (files) => {
    const newFileUploads = Array.from(files).map((file) => ({
      id: `file-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      name: file.name,
      status: "loading",
      message: "",
      response: null, // Initialize response as null
    }));
    setFileUploads((prev) => [...prev, ...newFileUploads]);

    const uploadPromises = Array.from(files).map((file) => uploadFile(file));
    Promise.all(uploadPromises).then((results) => {
      setFileUploads((prevUploads) =>
        prevUploads.map((upload) => {
          const result = results.find(
            (result) => result.file.name === upload.name
          );
          return result
            ? {
                ...upload,
                status: result.success ? "success" : "error",
                message: result.message,
                response: result.response, // Add the response object here
              }
            : upload;
        })
      );
    });
  };
  let renderMessageBar = false;
  if (config?.global?.PLAY_GROUND?.value === "YES" || messages.length > 0) {
    renderMessageBar = true;
  }
  if (assistModal) {
    renderMessageBar = false;
  }
  let navbarBg = useColorModeValue("whiteAlpha.500", "rgba(11,20,55,0.5)");
  const [scrolled, setScrolled] = useState(false);
  let creditLabel =
    configData?.config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ??
    "CHI";

  return (
    <Portal>
      <Box className="floatingMessage">
        <Box
          zIndex="2"
          position="fixed"
          boxShadow="none"
          bg={navbarBg}
          borderColor="transparent"
          filter="none"
          backdropFilter="blur(20px)"
          backgroundPosition="center"
          backgroundSize="cover"
          borderRadius="16px"
          borderWidth="1.5px"
          borderStyle="solid"
          transitionDelay="0s, 0s, 0s, 0s"
          transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
          transition-property="box-shadow, background-color, filter, border"
          transitionTimingFunction="linear, linear, linear, linear"
          alignItems={{ xl: "center" }}
          // display={secondary ? "block" : "flex"}
          minH="75px"
          justifyContent={{ xl: "center" }}
          lineHeight="25.6px"
          mx="auto"
          pb="8px"
          right={{
            base: "6px",
            md: "18px",
            lg: "26px",
            xl: "10px",
            "2xl": "24px",
          }}
          px={{
            sm: "15px",
            md: "10px",
          }}
          pt="8px"
          bottom={{ base: "11px", md: "15px", xl: "17px" }}
          w={{
            base: "calc(100vw - 6%)",
            md: "calc(100vw - 8%)",
            lg: "calc(100vw - 6%)",
            xl: "calc(100vw - 340px)",
            "2xl": "calc(100vw - 365px)",
          }}
          // w='100%'
        >
          <Flex
            w="100%"
            flexDirection={{
              sm: "column",
              md: "row",
            }}
            alignItems={{ xl: "center" }}
            mb="0px"
          >
            <Box mb={{ sm: "8px", md: "0px" }}></Box>
            <Box
              ms="auto"
              // w={{ sm: "100%", md: "unset" }}
              w="100%"
            >
              <Flex
                // w={{ sm: "100%", md: "auto" }}
                w="100%"
                alignItems="center"
                flexDirection="row"
                bg={menuBg}
                flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
                p="10px"
                borderRadius="30px"
                boxShadow={shadow}
                zIndex={-2222}
              >
                <>
                  <SearchBar
                    mb={secondary ? { base: "10px", md: "unset" } : "unset"}
                    // me="10px"
                    borderRadius="30px"
                    setQuestion={setQuestion}
                    question={question}
                    setMicModal={setMicModal}
                    micModal={micModal}
                    textareaEnterHandler={textareaEnterHandler}
                    placeholder={inputPlaceholder}
                    handleSend={handleSend}
                    selectedPrompt={selectedPrompt}
                    submitHandler={submitHandler}
                  />

                  {fileUploads.length > 0 && (
                    <Box
                      pl="10px"
                      pb="5px"
                      d="flex"
                      flexDirection={"column"}
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                    >
                      {fileUploads.map((file) => (
                        <FileUploadItem
                          file={file}
                          onRemove={handleRemoveFile}
                        />
                      ))}
                    </Box>
                  )}
                  {!responseProgressRef.current && (
                    <HoverIconButton
                      icon={FiSend}
                      label="Send"
                      onClick={handleSend}
                      iconColor={iconColor}
                      hoverColor={hoverColor}
                    />
                  )}

                  {selectedPrompt?.assistant?.assistantId && (
                    <FileUploadButton onFilesSelected={handleFilesSelected} />
                  )}
                  {/* {!selectedPrompt?.toVoiceMode && (
                                        <MicV2
                                            color={iconColor}
                                            hoverColor={hoverColor}
                                            audioTextBack={audioTextBack}
                                            recording={recording}
                                            setRecording={setRecording}
                                            setMicModal={setMicModal}
                                            micModal={micModal}
                                            // hideIcon={true}
                                            question={question}
                                            setQuestion={setQuestion}
                                            auto={auto}
                                            setAuto={setAuto}
                                            isSetQuestion={
                                                !(
                                                    isObjectEmpty(currentSessionInfo) &&
                                                    isObjectEmpty(selectedPrompt)
                                                )
                                            }
                                        />
                                    )} */}

                  {selectedPrompt?.toVoiceMode && (
                    <>
                      {config?.global?.VOICE_MODE_SWITCH?.value === "YES" ? (
                        <HoverIconButton
                          icon={FiPhone}
                          label="Phone"
                          onClick={() => setAssistModal(selectedPrompt)}
                          iconColor={iconColor}
                          hoverColor={hoverColor}
                        />
                      ) : (
                        // <Button
                        //   fontSize={"14px"}
                        //   width="40px"
                        //   height="40px"
                        //   mt="-1px"
                        //   bg="transparent"
                        //   p="0"
                        //   _hover={{
                        //     bg: "transparent",
                        //     backgroundColor: "rgb(230, 230, 230)",
                        //     borderRadius: "5px",
                        //   }}
                        //   onClick={() => {
                        //     // history.replace('/chat?mode=voice')
                        //     setAssistModal(selectedPrompt);
                        //   }}
                        //   _active={{}}
                        // >
                        //   <FiPhone size={19} />
                        // </Button>
                        <></>
                      )}
                    </>
                  )}

                  {Object.keys(selectedPrompt)?.length > 0 && (
                    <>
                      {!checkIsLast() && isLoading ? (
                        <>
                          {isLoading && (
                            <HoverIconButton
                              icon={FiSquare}
                              label="Stop"
                              onClick={handleStopResponse}
                              iconColor={iconColor}
                              hoverColor={hoverColor}
                            />
                            // <Button
                            //   fontSize={"14px"}
                            //   width="40px"
                            //   height="40px"
                            //   mt="-1px"
                            //   bg="transparent"
                            //   p="0"
                            //   _hover={{
                            //     bg: "transparent",
                            //     backgroundColor: "rgb(230, 230, 230)",
                            //     borderRadius: "5px",
                            //   }}
                            //   onClick={handleStopResponse}
                            //   _active={{}}
                            // >
                            //   <FiSquare size={19} color={iconColor} />
                            // </Button>
                          )}
                        </>
                      ) : (
                        <HoverIconButton
                          icon={FiRefreshCw}
                          label="Regenerate"
                          onClick={handleRegenerate}
                          iconColor={iconColor}
                          hoverColor={hoverColor}
                        />
                      )}

                      <HoverIconButton
                        icon={FiShare2}
                        label="Share"
                        onClick={handleShare}
                        iconColor={iconColor}
                        hoverColor={hoverColor}
                      />
                    </>
                  )}

                  <Flex
                    bg={ethBg}
                    // display={secondary ? "flex" : "none"}
                    borderRadius="30px"
                    p="6px"
                    align="center"
                    me="5px"
                    ms="auto"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      bg={ethBox}
                      h="30px"
                      // w="29px"
                      w="30px"
                      borderRadius="30px"
                      me="7px"
                    >
                      {/* <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} /> */}
                      <Text
                        as="span"
                        display={{ base: "none", md: "unset" }}
                        fontSize={"12px"}
                        fontWeight="700"
                        color={iconColor}
                      >
                        {" "}
                        {creditLabel}{" "}
                      </Text>
                      {/* <TbCircleLetterC color={ethColor} size={20} /> */}
                    </Flex>
                    <Text
                      w="max-content"
                      color={ethColor}
                      fontSize="12px"
                      me="6px"
                    >
                      {authState?.user?.subscriptionPlan?.unlimitedCredit ===
                      "yes" ? (
                        <BsInfinity size={"12px"} color={ethColor} />
                      ) : (
                        authState?.user?.creditBalance?.toLocaleString()
                      )}
                    </Text>
                  </Flex>
                </>
                <Button
                  variant="no-hover"
                  bg="transparent"
                  p="0px"
                  minW="unset"
                  minH="unset"
                  h="18px"
                  w="max-content"
                  onClick={toggleColorMode}
                >
                  <Icon
                    mr="5px"
                    h="18px"
                    w="18px"
                    color={iconColor}
                    as={colorMode === "light" ? IoMdMoon : IoMdSunny}
                  />
                </Button>
                {isFetched && (
                  <>
                    <Menu>
                      {configData?.config?.global?.WEB3_AUTHENTICATION
                        ?.value === "YES" && !authState.isAuthenticated ? (
                        <>
                          <Button
                            color={ethColor}
                            fontSize="sm"
                            fontWeight="700"
                            minW="unset"
                            h="40px"
                            w="175px"
                            bg={ethBg}
                            borderRadius="30px"
                            p="6px 10px"
                            align="center"
                            me="5px"
                            ms="auto"
                            onClick={() => {
                              onOpen();
                            }}
                          >
                            {t("Connect Wallet")}
                          </Button>

                          <InitialModal
                            isOpen={isOpen}
                            onOpen={onOpen}
                            onClose={onClose}
                          />
                        </>
                      ) : (
                        <MenuButton p="0px">
                          {authState?.user?.photo ? (
                            <Avatar
                              _hover={{ cursor: "pointer" }}
                              color="white"
                              name={
                                authState.isAuthenticated
                                  ? authState?.user?.firstName +
                                    " " +
                                    authState?.user?.lastName
                                  : "USER"
                              }
                              bg="#11047A"
                              size="sm"
                              w="40px"
                              h="40px"
                              src={authState?.user?.photo}
                            />
                          ) : (
                            <FaUserCircle color="#121212" size={"40px"} />
                          )}
                        </MenuButton>
                      )}
                      <MenuList
                        boxShadow={shadow}
                        p="0px"
                        mb="10px"
                        borderRadius="20px"
                        bg={menuBg}
                        border="none"
                      >
                        {!authState.isAuthenticated && (
                          <Flex w="100%" mb="0px">
                            <Text
                              ps="20px"
                              pt="16px"
                              pb="10px"
                              w="100%"
                              borderBottom="1px solid"
                              borderColor={borderColor}
                              fontSize="sm"
                              fontWeight="700"
                              color={textColor}
                            >
                              👋&nbsp; Hey,{" "}
                              {authState.isAuthenticated
                                ? authState?.user?.firstName
                                : "USER"}
                            </Text>
                          </Flex>
                        )}
                        <Flex flexDirection="column" p="10px">
                          {!authState.isAuthenticated && (
                            <>
                              <MenuItem
                                _hover={{ bg: "none" }}
                                _focus={{ bg: "none" }}
                                borderRadius="8px"
                                px="14px"
                                onClick={() => {
                                  history.push("/auth/sign-in");
                                }}
                              >
                                <Text fontSize="sm">{t("signin")}</Text>
                              </MenuItem>
                            </>
                          )}
                          {authState.isAuthenticated && (
                            <>
                              <Flex w="100%" mb="0px">
                                <Text
                                  ps="20px"
                                  pt="16px"
                                  pb="10px"
                                  w="100%"
                                  borderBottom="1px solid"
                                  borderColor={borderColor}
                                  fontSize="sm"
                                  fontWeight="700"
                                  color={textColor}
                                >
                                  👋&nbsp; Hey,{"  "}
                                  {authState.isAuthenticated
                                    ? authState?.user?.username
                                    : "USER"}
                                </Text>
                              </Flex>
                              <MenuItem
                                _hover={{ bg: "none" }}
                                _focus={{ bg: "none" }}
                                borderRadius="8px"
                                px="14px"
                                onClick={() => {
                                  history.push("/profile");
                                }}
                              >
                                <Text fontSize="sm">Profile Settings</Text>
                              </MenuItem>
                              <MenuItem
                                _hover={{ bg: "none" }}
                                _focus={{ bg: "none" }}
                                color="red.400"
                                borderRadius="8px"
                                px="14px"
                                onClick={() => {
                                  dispatch({
                                    type: "LOGOUT",
                                  });
                                  history.push("/auth/sign-in");
                                }}
                              >
                                <Text fontSize="sm">{t("Logout")}</Text>
                              </MenuItem>
                            </>
                          )}
                        </Flex>
                      </MenuList>
                    </Menu>
                  </>
                )}
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Portal>
  );
};

export default FloatingMessageBar;

import "assets/css/App.css";
import HomeNewLayout from "layouts/HomeNew";
import AdminLayout from "layouts/admin";
import AuthLayoutNew from "layouts/authNew";
import RTLLayout from "layouts/rtl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { Suspense, useContext, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PaymentSuccess from "./views/admin/main/others/paymentSuccess";
import Privacy from "./views/admin/main/others/privacy";
import Terms from "./views/admin/main/others/terms";
import { QueryClient, QueryClientProvider } from "react-query";

// Chakra imports
import { Box, ChakraProvider, Heading, Spinner } from "@chakra-ui/react";
import AuthProvider from "contexts/AuthContext";
import ConfigProvider from "contexts/ConfigContext";
import SessionListProvider from "contexts/SessionListContext";
import PagiProvider from "contexts/PagiContext";
import CustomRoute from "langHoc/CustomRoute";
import Blog from "layouts/blog";
import Demo from "layouts/demo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "theme/theme";
import "./i18n";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { lazy } from "react";
import { useViewportHeight } from "layouts/HomeNew/useViewportHeight";
import LandingPage from "views/landing";
import LogoutPage from "views/logout";
import { AuthContext } from "contexts/AuthContext";
import { userPrivateRequest } from "config/axios.config";
import Recoder from "views/admin/main/others/messages/Recorder";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Maintenance from "views/maintaince";
import { ConfigContext } from "contexts/ConfigContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ModalProvider from "contexts/ModalContext";
import { ModalContext } from "contexts/ModalContext";
import GetPaymentConfirmation from "components/new/GetPaymentConfirmation";
import PaypalSubscriptionPaymentStatusModal from "views/admin/modals/PaypalSubscriptionPaymentStatusModal";
import PricingModal from "./components/modals/PricingModal";
import useCustomHistory from "langHoc/useCustomHistory";
import usePaddle from "hooks/usePaddle";
const AskPdf = lazy(() => import("./views/ask-pdf"), {});
const MauerGrid = lazy(() => import("components/new/MauerGrid"), {});
const queryClient = new QueryClient();

function FontFamilyHandler() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const updateBodyClass = () => {
      if (i18n.language === "ko") {
        document.body.classList.add("ko");
      } else {
        document.body.classList.remove("ko");
      }
    };

    updateBodyClass();

    // Listen for language change and update the body class
    i18n.on("languageChanged", updateBodyClass);

    // Clean up the listener on unmount
    return () => {
      i18n.off("languageChanged", updateBodyClass);
    };
  }, [i18n]);
  const vh = useViewportHeight();
  useEffect(() => {
    localStorage.setItem("vh", vh * 100);
  }, []);

  return null;
}

function ModalHandler() {
  const configData = useContext(ConfigContext);
  const PLAN_STATIC_CONFIGURATION =
    configData?.config?.global?.PLAN_STATIC_CONFIGURATION?.value || {};
  const authData = useContext(AuthContext);
  const modalData = useContext(ModalContext);
  const { dispatch } = useContext(AuthContext);
  // const [modal, setModal] = useState(false);
  const [modalPreview, setModalPreview] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [loading, setLoading] = useState(false);
  const [paymentStep, setPaymentStep] = useState("package");
  const [intervalType, setIntervalType] = useState("YEARLY");
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const { modal, setModal } = authData;
  const [subscriptionStatusPreview, setSubscriptionStatusPreview] =
    useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [disabledPlan, setDisabledPlan] = useState(false);

  const url = window.location.href;
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const requestToUpgrade = params.get("requestToUpgrade");
  params.delete("requestToUpgrade");
  const history = useCustomHistory();
  const paddle = usePaddle();
  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  useEffect(() => {
    if (
      authData?.authState?.membership !== "pro" &&
      authData?.authState?.user?.creditBalance <= 0 &&
      authData?.authState?.user?.subscriptionStatus === "none" &&
      // true &&
      !window.location.href.includes("landing") &&
      !window.location.href.includes("profile")
    ) {
      setModalPreview(true);
    } else {
      setModalPreview(false);
    }

    if (
      authData?.authState?.user?.subscriptionStatus === "unconfirmedPayment" ||
      authData?.authState?.user?.hasPendingTopup === "yes"
    ) {
      setSubscriptionStatusPreview(true);
    } else {
      setSubscriptionStatusPreview(false);
    }
  }, [authData]);

  useEffect(() => {
    if (modalData.currentModalName == "membershipPackageModal") {
      setModal(true);
    } else if (requestToUpgrade == "yes") {
      setModal(true);
      history.replace(`${isKor ? "/ko" : "/en"}/chat`);
    }
  }, [modalData]);

  const paypalSubscriptionHandler = async (executablePrice = null) => {
    setLoading(true);
    if (!executablePrice) {
      executablePrice = selectedPlan;
    }
    try {
      const result = await userPrivateRequest
        .post("/create-paypal-subscription", {
          priceId:
            intervalType === "YEARLY"
              ? executablePrice?.paypalYearlyPlanId
              : executablePrice?.paypalMonthlyPlanId,
          planId: executablePrice?._id,
          intervalType,
        })
        .catch((err) => {});
      window.location.href = result.data.data.approval_url;
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const stripeSubscriptionHandler = async (executablePrice = null) => {
    setLoading(true);
    if (!executablePrice) {
      executablePrice = selectedPlan;
    }
    let priceId = executablePrice?.stripeMonthlyPriceId;
    if (intervalType === "YEARLY") {
      priceId = executablePrice?.stripeYearlyPriceId;
    }

    try {
      const result = await userPrivateRequest.post(
        "/create-stripe-subscription",
        {
          priceId,
          planId: executablePrice?._id,
          intervalType,
        }
      );
      window.location.href = result?.data?.url;
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  const paddleSubscriptionHandler = async (executablePrice = null) => {
    setLoading(true);
    if (!executablePrice) {
      executablePrice = selectedPlan;
    }
    let priceId = executablePrice?.paddleMonthlyPlanId;
    if (intervalType === "YEARLY") {
      priceId = executablePrice?.paddleYearlyPlanId;
    }
    let user = authData?.authState?.user;
    try {
      paddle?.Checkout.open({
        items: [
          {
            priceId: priceId, // you can find it in the product catalog
            quantity: 1,
          },
        ],
        customer: {
          ...(user?.paddleCustomerId
            ? { id: user.paddleCustomerId }
            : { email: user.email }),
        },
        customData: {
          // other custom metadata you want to pass
          uuid: user._id,
        },
        allowLogout: false,
        settings: {
          successUrl: `${process.env.REACT_APP_APP_LINK}en/profile`,
          // settings like successUrl and theme
        },
      });
    } catch (err) {
      toast.error(err.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {modalPreview &&
        configData?.config?.global?.WEB3_AUTHENTICATION?.value !== "YES" &&
        paymentStep !== "choose" && (
          <>
            <div
              className="modal-review-preview"
              onClick={() => setModal(true)}
            ></div>
          </>
        )}

      {configData?.config?.global?.WEB3_AUTHENTICATION?.value !== "YES" &&
        subscriptionStatusPreview && (
          <>
            <PaypalSubscriptionPaymentStatusModal />
          </>
        )}
      {paymentStep === "choose" && (
        <>
          <GetPaymentConfirmation
            onClose={() => setPaymentStep("package")}
            stripeCall={stripeSubscriptionHandler}
            paypalCall={paypalSubscriptionHandler}
          />
        </>
      )}

      {modal && (
        <PricingModal
          paymentStep={paymentStep}
          setPaymentStep={setPaymentStep}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          modal={modal}
          setModal={setModal}
          intervalType={intervalType}
          setIntervalType={setIntervalType}
          stripeSubscriptionHandler={stripeSubscriptionHandler}
          paypalSubscriptionHandler={paypalSubscriptionHandler}
          paddleSubscriptionHandler={paddleSubscriptionHandler}
        />
      )}
    </>
  );
}

function App() {
  const configData = useContext(ConfigContext);
  const authData = useContext(AuthContext);
  useEffect(() => {}, [authData]);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <ConfigProvider>
          <AuthProvider>
            <SessionListProvider>
              <ModalProvider>
                <PagiProvider>
                  <React.StrictMode>
                    <FontFamilyHandler />
                    <ModalHandler />
                    <QueryClientProvider client={queryClient}>
                      <Suspense fallback={<Spinner />}>
                        {/* <HelmetMetaData currentUrl="https://uata76bb54.chingu.fun/en/chat?reference=644b7e47b3508ae958ca1f7a"></HelmetMetaData> */}
                        <Switch>
                          <CustomRoute
                            exact
                            path={["/", "/:lang(en|ko)"]}
                            component={HomeNewLayout}
                          />
                          <CustomRoute
                            exact
                            path={[
                              "/execute-agreement",
                              "/:lang(en|ko)/execute-agreement",
                            ]}
                            component={PaymentSuccess}
                          />
                          <CustomRoute
                            path={["/chat", "/:lang(en|ko)/chat"]}
                            component={AdminLayout}
                          />

                          <CustomRoute
                            path={["/maintenance", "/:lang(en|ko)/maintenance"]}
                            component={Maintenance}
                          />
                          {/* <CustomRoute
     path={['/chat-2', '/:lang(en|ko)/chat-2']}
     component={AdminLayout}
   /> */}
                          <CustomRoute
                            path={["/profile", "/:lang(en|ko)/profile"]}
                            component={AdminLayout}
                          />
                          {/* <CustomRoute
                            path={["/rewards", "/:lang(en|ko)/rewards"]}
                            component={AdminLayout}
                          /> */}
                          <CustomRoute
                            path={["/auth", "/:lang(en|ko)/auth"]}
                            component={AuthLayoutNew}
                          />
                          <CustomRoute
                            path={["/rtl", "/:lang(en|ko)/rtl"]}
                            component={RTLLayout}
                          />
                          <CustomRoute
                            path={["/recorder", "/:lang(en|ko)/recorder"]}
                            component={Recoder}
                          />
                          <CustomRoute
                            path={["/terms", "/:lang(en|ko)/terms"]}
                            component={Terms}
                          />
                          {/* <CustomRoute
       path={["/home-2", "/:lang(en)/home-2"]}
       component={Home2}
     /> */}
                          <CustomRoute
                            path={["/landing", "/:lang(en|ko)/landing"]}
                            component={LandingPage}
                          />
                          <CustomRoute
                            path={["/logout", "/:lang(en|ko)/logout"]}
                            component={LogoutPage}
                          />

                          <CustomRoute
                            path={["/ask-pdf", "/:lang(en|ko)/ask-pdf"]}
                            component={AskPdf}
                          />
                          <CustomRoute
                            path={["/masonary", "/:lang(en|ko)/masonary"]}
                            component={MauerGrid}
                          />
                          <CustomRoute
                            path={["/privacy", "/:lang(en|ko)/privacy"]}
                            component={Privacy}
                          />
                          {/* <CustomRoute
       path={["/pro-sign-up", "/:lang(en)/pro-sign-up"]}
       component={Pricing}
     /> */}
                          <CustomRoute
                            path={["/Blog", "/:lang(en|ko)/Blog"]}
                            component={Blog}
                          />
                          <CustomRoute
                            path={["/demo", "/:lang(en|ko)/demo"]}
                            component={Demo}
                          />
                        </Switch>
                      </Suspense>
                    </QueryClientProvider>

                    <ToastContainer
                      position="bottom-center"
                      autoClose={5000}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      theme="dark"
                    />
                  </React.StrictMode>
                </PagiProvider>
              </ModalProvider>
            </SessionListProvider>
          </AuthProvider>
        </ConfigProvider>
      </Router>
    </ChakraProvider>
  );
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <App />
  </Elements>,
  document.getElementById("root")
);

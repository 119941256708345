import {
  Box,
  Button,
  Flex,
  useDisclosure,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logoImage from "assets/img/ads.png";
import MessageBlock from "components/chat/MessageBlock";
import { AuthContext } from "contexts/AuthContext";
import { sixGradiantColor } from "contexts/color";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SearchResult from "./SearchResult";
import { userPrivateRequest } from "config/axios.config";
import { ConfigContext } from "contexts/ConfigContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import VideoProgressBar from "./VideoProgressBar";
import MessageBlockAssistant from "components/chat/MessageBlockAssistant";
import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import MusicCard from "./MusicCard";

export default React.memo(function MessageRow({
  iteration,
  message,
  config,
  isSearchShow,
  isLast,
  handleRegenerate,
  handleStopResponse,
  refid,
  updateMessage,
  generateOneClickVideo,
  continueMessage,
  onShareModalOpen,
  selectedPrompt,
}) {
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const copyHandler = () => {
    let humanMessage = message.human.text;
    if (message.criteria === "blog") {
      humanMessage = "Blog Post Creation ";
    } else if (message.criteria === "blog-pro") {
      humanMessage = "Blog Post Creation  Pro";
    } else if (message.criteria === "news") {
      humanMessage = "Video Script Creation";
    } else if (message.criteria === "news-pro") {
    } else if (message.criteria === "survey") {
      humanMessage = "Survey Result Summary";
    } else if (message.criteria === "news-pro") {
      humanMessage = "Video Script Creation Pro";
    } else if (message.criteria === "deep-studies") {
      humanMessage = "Perform Deep Studies";
    } else if (message.criteria === "video") {
      humanMessage = "Video of";
    }
    navigator.clipboard.writeText(
      (humanMessage + "\n" + message.ai.text).replace()
    );
    toast.success("Copied");
  };
  const authData = useContext(AuthContext);
  let membership = authData?.authState?.membership;
  let adsencePosition = config[membership].adsensePositionAfterPrompt;
  const myDivRef = useRef(null);
  const copyUrl = `${process.env.REACT_APP_APP_LINK}/chat-2?sharedSession=${refid}`;
  const configData = useContext(ConfigContext);
  const VIDEO_CONFIGURATION =
    configData?.config?.global?.VIDEO_CONFIGURATION?.value || {};

  const COLORS = configData?.config?.global?.COLORS?.value;

  const primaryColor = useColorModeValue(
    COLORS.primaryColor,
    COLORS.primaryDarkColor
  );

  function scrollToMiddle() {
    if (message?.ai?.load) {
      const rect = myDivRef.current.getBoundingClientRect();
      const desiredOffset = -400;
      const absoluteTopPosition = window.pageYOffset + rect.top + desiredOffset;

      window.scrollTo({
        top: absoluteTopPosition,
        behavior: "smooth",
      });
    }
    // console.log(isLast, message?.ai?.load, 'scroll 93993')
  }
  useEffect(() => {
    setShowReference(isLast ? true : false);
    scrollToMiddle();
    // console.log(isLast, message?.ai?.load, 'scroll 93993')
  }, [isLast, message?.ai?.text]);
  // console.log(isLast, message?.ai?.load, 'scroll 93993')
  const [showReference, setShowReference] = useState(true);

  useEffect(() => {
    // console.log(
    //   message.criteria,
    //   message?.ai?.video,
    //   'message?.ai'
    // )
    if (
      message.criteria === "video" &&
      message?.ai?.video?.status !== "ready" &&
      message?.ai?.video?._id &&
      message?.ai?.video?.type === "elai"
    ) {
      let intervalId = setInterval(async function () {
        // // console.log(message, 'message?.ai')
        try {
          let result = await userPrivateRequest.get(
            `/get-video/${message?.ai?.video?._id}`
          );
          if (result?.data?.video?.status === "ready") {
            updateMessage(message?.id, {
              ...result?.data?.video,
              type: "elai",
            });
            clearInterval(intervalId);
          }
        } catch (err) {}
      }, 10000);
    }
    let videoStatus = false;

    if (
      message?.ai?.video?.status !== "completed" &&
      message?.ai?.video?.status !== "failed"
    ) {
      videoStatus = true;
    }

    // console.log(message?.ai?.video?.status !== "completed" && message?.ai?.video?.status !== "failed", videoStatus, 'videoStatus')
    if (
      message.criteria === "video" &&
      videoStatus &&
      message?.ai?.video?._id &&
      message?.ai?.video?.type === "shortGpt"
    ) {
      let intervalId = setInterval(async function () {
        // // console.log(message, 'message?.ai')
        try {
          let result = await userPrivateRequest.get(
            `/video-generation/info/${message?.ai?.video?._id}`
          );
          if (result?.data?.data?.status === "completed") {
            updateMessage(message?.id, {
              ...result?.data?.data,
              type: "shortGpt",
            });
            clearInterval(intervalId);
          }
          if (result?.data?.data?.status === "failed") {
            updateMessage(message?.id, {
              ...result?.data?.data,
              type: "shortGpt",
            });
            clearInterval(intervalId);
          }
        } catch (err) {}
      }, 10000);
    }
  }, [message.criteria, message?.ai?.video]);

  // // console.log(message, message?.ai?.video?._id, "message?.ai");
  const videoStatus =
    message?.ai?.video?.status === "ready" ||
    message?.ai?.video?.status === "completed" ||
    message?.ai?.video?.status === "failed";
  // console.log(message?.ai, 'messsage ai')
  function parseContent(content) {
    try {
      // Try to parse the content as JSON
      const parsed = JSON.parse(content);
      // console.log(parsed, 'Parsed as JSON');
      return true;
    } catch (e) {
      // If parsing fails, it's likely a normal string
      // console.log(content, 'Content is a normal string');
      return false;
    }
  }

  return (
    <Box ref={myDivRef} overflowX={"hidden"} px="20px">
      <Box
        display="flex"
        flexWrap="wrap"
        // padding={'20px 0px'}
        id="SINGLE-ANSWER"
      >
        {/*//! MAIN-RESULT */}
        <Box
          w="100%"
          pr={isSearchShow ? "0" : "20px"}
          mt="20px"
          id="MAIN-RESULT"
        >
          <Flex mb="0px" overflow="hidden" w="100%" ms="auto" justify="end">
            <Flex
              direction="column"
              w="calc(100%)"
              boxSizing="border-box"
              alignItems="flex-end"
              id="MessageBlock"
            >
              {parseContent(message?.human?.text) && (
                <MessageBlockAssistant
                  seen
                  responseType="question"
                  content={message.human.text || ""}
                  time={message.human.time || ""}
                  copyHandler={copyHandler}
                  criteria={message.criteria}
                  hidden={
                    message?.human?.text.toLowerCase() === "continue" && true
                  }
                  extra={
                    message?.user?.username &&
                    authData?.authState?.user?.username !==
                      message?.user?.username
                      ? `Asked by ${message?.user?.username}`
                      : ""
                  }
                  fullLoaded={true}
                />
              )}
              {!parseContent(message.human.text) && (
                <MessageBlock
                  seen
                  responseType="question"
                  content={message.human.text || ""}
                  time={message.human.time || ""}
                  copyHandler={copyHandler}
                  criteria={message.criteria}
                  hidden={
                    message?.human?.text.toLowerCase() === "continue" && true
                  }
                  extra={
                    message?.user?.username &&
                    authData?.authState?.user?.username !==
                      message?.user?.username
                      ? `Asked by ${message?.user?.username}`
                      : ""
                  }
                  fullLoaded={true}
                />
              )}
            </Flex>
          </Flex>
          {message.criteria !== "video" && (
            <Flex overflow="hidden">
              <Flex
                direction="column"
                w="100%"
                maxW={{ base: "100%", lg: "calc(100% - 0px)" }}
                boxSizing="border-box"
              >
                {parseContent(message?.ai?.text) ? (
                  <MessageBlockAssistant
                    responseType="answer"
                    content={message?.ai?.text || ""}
                    time={message?.ai?.time || ""}
                    load={message?.ai?.load || false}
                    files={message?.ai?.files || []}
                    side="left"
                    google={true}
                    copyHandler={copyHandler}
                    fullLoaded={message?.ai?.fullLoaded}
                    selectedPrompt={selectedPrompt}
                  />
                ) : (
                  <MessageBlock
                        responseType="answer"
                        content={message?.ai?.text || ""}
                        time={message?.ai?.time || ""}
                        load={message?.ai?.load || false}
                        files={message?.ai?.files || []}
                        side="left"
                        google={true}
                        copyHandler={copyHandler}
                        fullLoaded={message?.ai?.fullLoaded}
                    selectedPrompt={selectedPrompt}
                    criteria={message.criteria}
                  />
                )}
              </Flex>
            </Flex>
          )}
          {message.criteria === "video" && (
            <Flex overflow="hidden">
              <Flex
                direction="column"
                w="100%"
                maxW={{ base: "100%", lg: "calc(100% - 0px)" }}
                boxSizing="border-box"
              >
                {message?.ai?.load && (
                  <div className="loading-area update-area">
                    {/* <Box paddingTop="20px">
                      <SkeletonText
                        mt="0"
                        noOfLines={1}
                        spacing="5"
                        skeletonHeight="10"
                      />
                      <SkeletonText
                        mt="4"
                        noOfLines={5}
                        spacing="5"
                        skeletonHeight="3"
                      />
                    </Box> */}
                  </div>
                )}

                <Box
                  d="flex"
                  justifyContent="center"
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  {((!videoStatus && message?.ai?.video?._id) ||
                    message?.ai?.video?.status === "failed") && (
                    // <AtomicSpinner displayElectronPaths={false} />
                    <VideoProgressBar
                      messageId={message.id}
                      id={message?.ai?.video?._id}
                      status={message?.ai?.video?.status}
                      video={message?.ai?.video}
                      updateMessage={updateMessage}
                    />
                  )}
                  {!videoStatus && (
                    <Text ml="calc(3% + 30px)" marginTop={"5px"}>
                      {" "}
                      {isKor
                        ? VIDEO_CONFIGURATION?.loadingMessageKr
                        : VIDEO_CONFIGURATION?.loadingMessage}{" "}
                    </Text>
                  )}
                </Box>

                {message?.ai?.video?.status === "ready" && (
                  <>
                    {/* <p>Ready</p> */}

                    <video src={message?.ai?.video?.url} controls></video>
                  </>
                )}

                {message?.ai?.video?.status === "completed" && (
                  <>
                    {message?.ai?.video?.videos?.map((vd) => {
                      return (
                        <video
                          src={
                            "https://chingu.s3.ap-northeast-2.amazonaws.com/" +
                            vd
                          }
                          controls
                        ></video>
                      );
                    })}
                  </>
                )}
                {/* {message?.ai?.video?.status === "failed" && (
                  <Flex overflow="hidden">
                    <Flex
                      direction="column"
                      w="100%"
                      maxW={{ base: "100%", lg: "calc(100% - 0px)" }}
                      boxSizing="border-box"
                    >
                      <MessageBlock
                        responseType="answer"
                        content={
                          "Video Production was unsuccessful. Although this is rare, we are aware of this issue and working to improve our systems already." ||
                          ""
                        }
                        time={""}
                        load={false}
                        side="left"
                        google={true}
                        copyHandler={copyHandler}
                      />
                    </Flex>
                  </Flex>
                )} */}
              </Flex>
            </Flex>
          )}
          {message?.includeGoogleData != "no" && (
            <Box
              w="100%"
              pr="20px"
              pt="12px"
              maxHeight={"0px"}
              display={message.ai.fullLoaded ? "block" : "none"}
              style={{
                transition: "all 0.1s linear",
                overflow: "hidden",
                scrollbarWidth: "none",
                ...(showReference && { maxHeight: "calc(100% - 100px)" }),
              }}
            >
              <SearchResult
                googleSearch={message.searchResult || []}
                chatId={message.id}
                config={config}
              />
            </Box>
          )}
        </Box>

        {/*//! SIDE-RESULTS */}
        {showReference && message?.includeGoogleData != "no" ? (
          <Box
            w="30%"
            pr="20px"
            pt="15px"
            pl={"20px"}
            style={{
              transition: "all 0.3s linear",
              overflow: "hidden",
              scrollbarWidth: "none",
            }}
            id="SIDE-RESULTS"
            display={{
              base: "none",
              lg: message.ai.fullLoaded ? "block" : "none",
            }}

            // display={message.ai.fullLoaded ? "block" : "none"}
            //TODO: 101
          >
            {/* <SideBarSearch
              googleSearch={message.searchResult || []}
              chatId={message.id}
              config={config}
              message={message}
            /> */}
          </Box>
        ) : (
          <></>
        )}
      </Box>

      {/* //! SEPARATOR */}
      <Box
        w="100%"
        // w={{base: "100%", lg: isSearchShow && showReference ? "70%" : "100%"}}
        borderTop=".5px solid #a3aed0"
        display="flex"
        justifyContent={"center"}
        mt="20px"
        // mb="10px"
        mx="auto"
      >
        <Box
          display="flex"
          gap="10px"
          mt="-11px"
          bg="transparent"
          px="10px"
          // pb="50px"
        >
          <Button
            fontSize={"14px"}
            p="0.25rem 0.4rem"
            h="21px"
            color={"#fff"}
            fontFamily={"Noto Sans"}
            borderRadius="4px"
            onClick={copyHandler}
            bg={primaryColor}
            _hover={{
              bg: { primaryColor },
            }}
            _active={{
              bg: { primaryColor },
            }}
          >
            {" "}
            {t("Copy")}
          </Button>
          {isLast && (
            <>
              <Button
                fontSize={"14px"}
                p="0.25rem 0.4rem"
                h="21px"
                color={"#fff"}
                borderRadius="4px"
                fontFamily={"Noto Sans"}
                onClick={handleRegenerate}
                bg={primaryColor}
                _hover={{
                  bg: { primaryColor },
                }}
                _active={{
                  bg: { primaryColor },
                }}
              >
                {t("Regenerate")}
              </Button>

              {/* <Button
                fontSize={'14px'}
                p="0.25rem 0.4rem"
                h="21px"
                color={'#fff'}
                borderRadius="4px"
                fontFamily={'Noto Sans'}
                onClick={handleStopResponse}
                bg={primaryColor}
                _hover={{
                  bg: { primaryColor },
                }}
                _active={{
                  bg: { primaryColor },
                }}
              >
                {' '}
                {t('Stop')}
              </Button> */}

              {(selectedPrompt?.allowVideoCreate === "SHORTGPT" ||
                selectedPrompt?.allowVideoCreate === "ELAI" ||
                selectedPrompt?.allowVideoCreate === "BOTH") && (
                <Button
                  fontSize={"14px"}
                  p="0.25rem 0.4rem"
                  h="21px"
                  color={"#fff"}
                  borderRadius="4px"
                  fontFamily={"Noto Sans"}
                  onClick={generateOneClickVideo}
                  bg={primaryColor}
                  _hover={{
                    bg: { primaryColor },
                  }}
                  _active={{
                    bg: { primaryColor },
                  }}
                >
                  {" "}
                  {t("CreateVideo")}
                </Button>
                )}
              
              {selectedPrompt?.promptType !== "tts" && (
                <Button
                fontSize={"14px"}
                p="0.25rem 0.4rem"
                h="21px"
                color={"#fff"}
                borderRadius="4px"
                fontFamily={"Noto Sans"}
                onClick={continueMessage}
                bg={primaryColor}
                _hover={{
                  bg: { primaryColor },
                }}
                _active={{
                  bg: { primaryColor },
                }}
              >
                {" "}
                {t("Continue")}
              </Button>
            )}
            </>
          )}

          {message?.includeGoogleData != "no" && (
            <Button
              fontSize={"14px"}
              p="0.25rem 0.4rem"
              h="21px"
              color={"#fff"}
              borderRadius="4px"
              fontFamily={"Noto Sans"}
              onClick={() => {
                if (showReference) {
                  setShowReference(false);
                } else {
                  setShowReference(true);
                }
              }}
              bg={primaryColor}
              _hover={{
                bg: { primaryColor },
              }}
              _active={{
                bg: { primaryColor },
              }}
            >
              {showReference ? t("HideSources") : t("ShowSources")}
            </Button>
          )}
          <Button
            fontSize={"14px"}
            p="0.25rem 0.4rem"
            h="21px"
            color={"#fff"}
            borderRadius="4px"
            fontFamily={"Noto Sans"}
            onClick={() => {
              onShareModalOpen();
              // if (showReference) {
              //   setShowReference(false)
              // } else {
              //   setShowReference(true)
              // }
            }}
            bg={primaryColor}
            _hover={{
              bg: { primaryColor },
            }}
            _active={{
              bg: { primaryColor },
            }}
          >
            {t("Share")}
          </Button>
        </Box>
      </Box>

      {!(iteration % adsencePosition) && (
        <>
          <Box width={"100%"} mb="50px">
            <img
              src={logoImage}
              alt="logo"
              style={{
                width: "calc(100% - 20px)",
              }}
            />
          </Box>
        </>
      )}

      {/*//! SHARE MODAL  */}
    </Box>
  );
});

import { userPublicRequest } from "config/axios.config";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import Pusher from "pusher-js";
import { AuthContext } from "./AuthContext";
import { ConfigContext } from "./ConfigContext";
import axios from "axios";
import { v4 } from "uuid";
import { Buffer } from "buffer";
import { indexBy, uniq, where } from "underscore";
import { useTranslation } from "react-i18next";
import ModuleEligibilityModal from "views/admin/main/others/messages/modals/ModuleEligibilityModal";
import TtsModal from "components/modals/TtsModal.jsx";
export const ModalContext = createContext();
const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const ModalProvider = ({ children, ...rest }) => {
  const authData = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const membership = authData?.authState?.membership;
  const [currentModalName, setCurrentModalName] = useState(null);
  const [modalData, setModalData] = useState({});

  console.log("modalData", modalData);

  return (
    <ModalContext.Provider
      value={{
        setCurrentModalName,
        currentModalName,
        modalData,
        setModalData,
      }}
    >
      <>
        {currentModalName === "moduleEligibilityException" && (
          <ModuleEligibilityModal />
        )}
        {currentModalName === "ttsModal" && <TtsModal {...modalData} />}

        {children}
      </>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
